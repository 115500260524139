@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');

:root {
    --base-gray: #dfdfdf;
}

.launch-container {
    width: 100%;
    height: fit-content;
    background-color: var(--main-color);
    background-size: cover;
    padding-top: 170px;
    padding-bottom: 100px;


}


.fa-solid,
.fas {
    font-family: "Font Awesome 6 Pro" !important;
    font-weight: 900;
}

.launch-viewer {
    margin: auto;
    width: 450px;

    padding: 15px;
    height: fit-content;
    border-radius: 25px;
    background-color: white;

}

.mob-logo-container {
    display: flex;
    justify-content: center;
}

.mob-logo-container>img {
    width: 200px;
}

.stack-plate {
    backdrop-filter: blur(10px);
    border-radius: 11px;
}



.call-btn {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 0px 15px;
}

.call-btn>button {
    background-color: var(--main-color);
    color: white;
    border: none;
    padding: 15px;
    font-size: 17px;
    font-weight: 700;
    border-radius: 59px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
}

.call-btn>button:last-of-type {
    background-color: white;
    color: black;
    font-weight: 700;
    border: solid 1px lightgray;

}

.launch-text {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: white;
    height: 400px;
}

.launch-text>img {
    width: 170px
}

.launch-btns {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

}

.launch-text>h1 {
    font-size: 50px;
    font-weight: 700;
    text-align: center;
    color: black;
    line-height: 55px;
    font-family: 'Josefin Sans', sans-serif !important;
}

.launch-text>p {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    line-height: 25px;
    width: 60%;
    color: var(--text-gray);
}

.btn-stack {
    display: flex;
    flex-direction: column;
    padding: 15px;
    color: white;
}

.stack-plate>.stk-btn:first-of-type {
    border-radius: 13px 13px 0px 0px;
    border: none;
}

.stack-plate>.stk-btn:last-of-type {
    border-radius: 0px 0px 13px 13px;
    border: none;
}

.stk-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 18px;
    background-color: #0000001c;
    border:
        none;
    ;
}

.stk-plate {
    display: flex;
    align-items: center;
    gap: 20px;
}

.stk-text {
    display: flex;
    align-items: center;
    gap: 20px;
}

.stk-btn-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
}

.stk-btn-text>h2 {
    font-size: 18px;
    font-weight: 600;
}

.stk-btn-text>h3 {
    font-size: 16px;
    font-weight: 600;
}

.stk-btn-text>span {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    line-height: 20px;
}

.page-content {

    width: 100%;
    height: 100vh;
    z-index: 150;
    background-color: var(--main-color);


}

.onboard-content {
    width: 100%;
    margin-top: 150px;
    margin: auto;
    width: 450px;

    padding: 15px;
    height: fit-content;
    border-radius: 25px;
    background-color: white;

}

.onboard-content>div {
    margin: auto;
    width: 100%;
}

.stk-btn-static>.stk-text {
    color: black;
}

.terms-conditions {
    color: black;
}

.terms-conditions>a {
    text-decoration: underline !important;
}

.stk-btn-static {
    background-color: var(--grayBackground);

    margin-top: 15px;
    position: static !important;

}

.mob-navbar {
    width: 100%;
    height: 53px;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.mob-navbar>h3 {
    font-size: var(--px16);
    font-weight: 600;
}

.nav-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: contain;
}

.share-btn {
    background-color: transparent;
    border: none;
    color: var(--main-color);
    width: 67px;
    justify-content: flex-end;
    display: flex;
}

.share-btn>i {
    font-size: 27px;
}

.back-btn {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    color: white;
    font-size: var(--px18);
    font-weight: 600
}

.page-content {
    padding: 25px;
    background-size: cover;
    background-repeat: no-repeat;
}

.page-title {
    font-size: 40px;
    color: black;
    font-weight: 700;
    margin-bottom: 25px;
    margin-top: 10px;
    font-family: 'Josefin Sans', sans-serif !important;
}

.page-desc-text {
    font-size: 13px;
    color: black;
    font-weight: 400;
    margin-bottom: 8px;
    text-align: center;
    padding: 10px 0px;
    margin-top: 6px !important;
}

.page-desc-text>a {
    font-weight: 600;
    text-decoration: underline;
}

.page-desc-text>span {
    font-weight: 600;
    color: white;
}





.login-outlet {

    width: 100%;
}







.stack-divider {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.divider {
    width: 90%;
    height: 1px;
    background-color: transparent;
}


.divider-solid {
    width: 95%;
    height: 1px;
    background-color: rgb(230, 230, 230);
    position: absolute;
    right: 0px;

}











.input-stack {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.input-stack>input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 15px;
    background-color: white;
    border: none;
    border-radius: 0px;
    color: black;
    border: solid 1px var(--hoverBorder);
    margin-bottom: -2px;
}

.input-stack>.PhoneInput {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border: none;

    border-radius: 0px;
    padding-left: 18px;
    border: solid 1px var(--hoverBorder);
    margin-bottom: -2px;

}

.input-stack>.PhoneInput>input {
    background: white;
    color: black;
    width: 100%;
    border-radius: 0px 15px 0px 0px;
    padding: 18px;

    border: none;
}


.input-stack>input::placeholder,
.input-stack>.PhoneInput>input::placeholder {
    color: rgba(0, 0, 0, 0.551);
    font-size: var(--px17);
    font-weight: 400;
}

.input-stack>*:last-child {
    border-radius: 0px 0px 13px 13px;

}

.input-stack>*:first-child {
    border-radius: 13px 13px 0px 0px;
}

.lg-button {
    background-color: var(--main-color);
    border: none;
    width: 100%;
    padding: 15px;
    font-size: var(--px18);
    color: white;
    font-weight: 700;
    border-radius: 59px;
}

.lg-button:active {
    opacity: 0.8;
}

.mantine-Menu-itemSection>i {
    color: var(--main-color);
    font-size: 20px;
}

.m-38a85659 {
    border-radius: 13px
}

.static-lg-button {
    background-color: var(--main-color);
    color: white;
}

.group-flow {
    width: 100%;
    display: flex;
    gap: 16px;
}

.group-flow>button {
    font-size: 15px !important;
    ;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.group-flow>button>i {
    font-size: 18px;
}

.static-lg-flat-button {
    background-color: var(--base-gray);
    border: none;
    color: black
}

.flat-btn {
    background-color: transparent;
    border: none;
    color: var(--main-color);
    margin-top: -10px;
}

.react-swipeable-view-container {
    justify-content: flex-end;
    max-width: 620px;
    margin: auto;
}

.form-styling {
    display: flex;
    flex-direction: column;
    gap: 17px;
    padding-bottom: 0px;
}

.static-form>.input-stack>input {
    background-color: rgb(0 0 0 / 7%);
    color: black;
}

.static-form>.input-stack>input::placeholder {
    color: rgb(93, 93, 93);
}

.mantine-Select-input {
    background-color: rgb(255 255 255 / 18%) !important;
}

.static-form>.input-stack>textarea {
    background-color: rgb(0 0 0 / 7%);
    color: black;
    border: none;
    height: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 18px;

    border:
        none;
    border-radius: 13px;
}

input {
    outline: none;
}

input:focus {
    outline: solid 2px var(--main-color);
    z-index: 3;
}


textarea {
    outline: none;
}

textarea:focus {
    outline: solid 2px var(--main-color);
    position: relative;
    z-index: 3;
}

.static-form {
    width: 100%;
}

.static-form>.input-stack>textarea::placeholder {
    color: rgb(93, 93, 93);
    font-size: var(--px17);
    font-weight: 400;
}

.form-styling>img {
    border-radius: 13px;
}

.input-stack>div>label {
    display: none;
}

.input-stack>label {
    text-decoration: none;
    word-break: break-word;
    color: #464646;
    font-size: calc(1rem - 0.125rem);
    line-height: 1.2;
    display: block;
    display: block;
    margin-bottom: 7px;
}

.input-stack>div>div>button {
    background-color: rgba(255, 255, 255, 0.33);
    color: black;
    border: none;
    height: 54px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 18px;

    border:
        none;
    border-radius: 13px;
}

.input-stack>div>div>button>span {
    font-size: var(--px17);
    font-weight: 400;
    color: rgb(93, 93, 93);
}


.input-stack>select {
    background-color: #f1f3f5;
    color: black;
    border: none;
    height: 54px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 18px;

    border:
        none;
    border-radius: 13px;
}

.btn-with-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px;
    width: 100%;
    gap: 10px;
    background-color: var(--frostedBackground);
    border: none;
    border-radius: 13px;
    color: white;
    font-weight: 600;
    font-size: var(--px18);
}

.page-subTitle {
    width: 100%;
    display: inline-block;
    gap: 6px;
    color: black;

    margin-top: -15px;
    /* border-bottom: var(--frostedBorder); */


    opacity: 0.7;
}

.page-subTitle>p {
    font-weight: 600;
}









.mantine-Tooltip-tooltip {
    font-weight: normal;
}

.static-title {
    color: black;
    margin-bottom: 0px;
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
}

.static-content {
    background-color: transparent;
    z-index: 1;
    position: relative;
}

.strip-item {
    display: flex;
    padding: 20px 0px;
    justify-content: space-between;
    align-items: center;
}

.strip-item-right {
    display: flex;
    align-items: center;

}



.strip-item-right>i {
    font-size: 25px;
    color: var(--item-gray);
    margin-right: 10px;
}

.strip-item-left {
    display: flex;
    gap: 15px;
}

.strip-item-left>img {
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.12);
    width: 50px;
    height: 50px;
    border-radius: 10px;
    object-fit: contain;
}

.strip-item-text {
    display: blockflex;
    flex-direction: column;
    gap: 5px;
}

.strip-item-text>h4 {
    font-size: var(--px15);
    font-weight: 700;
}

.strip-item-text>p {
    font-size: var(--px13);
    font-weight: 500;
    color: var(--item-gray);
}














.leaderboard-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0px
}

.limit-padding {
    padding: 0px !important
}

.featured-item {

    padding: 0px 0px;
    margin-top: 15px;

}

.leaderboard-item-left-name>span {
    font-weight: 600;
    font-size: 14px;
    color: var(--main-color)
}

.leaderboard-item-left-name>h3 {
    font-size: var(--px16);
    font-weight: 500;
}

.leaderboard-item-left-name>p {
    font-size: var(--px14);
    font-weight: 400;
    color: var(--text-gray)
}

.leaderboard-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.leaderboard-item-right-place>span {
    font-size: var(--px15);
    font-weight: 600;
    color: var(--main-color);
}

.leaderboard-item-left {
    display: flex;
    align-items: center;
    gap: 15px;
}

.mantine-1m5sxin {
    width: 2.9rem;
    height: 2.9rem;
    min-width: auto;
}

.mantine-1m5sxin>img {
    object-fit: cover;
}












.overview-chunk {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 20px;
    margin-bottom: 10px;
    margin-top: -50px;
}

.mantine-cvdx6e[data-active],
.mantine-cvdx6e[data-active]:hover {
    color: var(--main-color);
    font-weight: 600;
}

.mantine-cvdx6e {
    transition: 0.2s ease;
}

.ov-text {}

.ov-text>h1 {
    font-size: 21px;
    font-weight: 700;
}

.ov-text>span {
    font-size: var(--px15);
    font-weight: 500;
    color: var(--text-gray);
}

.overview-chunk>img {
    width: 100px;
    height: 100px;
    border: solid 1px #00000017;
    border-radius: 16px;
    margin-top: 10px;
    object-fit: contain;
    background-color: white;
}


.mantine-15po0m8 {}



.tab-container {

    border-bottom: solid 1px rgba(0, 0, 0, 0.081);
    position: sticky;
    top: 50px;
    z-index: 100;
    background-color: white;
}










.info-large {
    margin-bottom: 20px;
}

.info-large>span {
    font-size: var(--px13);
    font-weight: 500;
    color: var(--text-gray);
    text-transform: uppercase;
}

.info-strip-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.info-strip>.info-item:first-of-type>h3 {
    color: var(--main-color);
}

.info-large>h3 {
    font-size: var(--px19);
    font-weight: 700;
    color: var(--main-color)
}

.info-large>h3>span {
    font-weight: 400;
    font-size: var(--px11);
    color: black;
}

.info-strip {
    display: flex;
    /* grid-template-columns: 2.4fr 0.05fr 1fr 0.05fr 1fr 0.05fr 1fr; */

    gap: 22px;
    align-items: center;
    padding: 5px 0px;



}

.m-dc9b7c9f {
    border-radius: 13px !important
}

.info-item>h3>span {
    font-weight: 500;
    font-size: var(--px11);
    color: black;
}

.info-item>span {
    font-size: 10px;
    text-transform: uppercase;
    color: var(--text-gray);
    font-weight: 600;
}

.info-item>h3 {
    font-size: 15px;
    font-weight: 700;
    color: black;
}

.vert-divider {
    height: 30px;
    display: flex;
    align-items: center;
}

.vert-divider-line {
    height: 100%;
    width: 1px;
    background-color: var(--hoverBorder);
}


.contribution {


    margin-top: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.contribution>.info-strip {
    display: flex;
    gap: 15px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    margin-top: 0px;
    padding-top: 0px;
}

.contribute-title {
    display: flex;
    gap: 13px;
    align-items: center;
    margin-bottom: 5px;
}

.contribute-title>h3 {
    font-size: var(--px15);
    font-weight: 600;
    margin-bottom: 0px;

}

.contribute-text {
    text-align: center;
    font-size: var(--px13);
    color: var(--text-gray);
    padding: 15px;
}

.contribute-strip {
    display: flex;
    justify-content: space-between;
    align-items: center;
    ;
}

.contribute-strip>h3 {
    font-size: 15px;
    font-weight: 600;
    color: var(--main-color);
    margin-right: 10px;
}

.contribute-title>h3>p {
    font-size: 14px;
    font-weight: normal;
}


.mantine-Tabs-panel {
    margin-bottom: 50vh;
}

.mantine-Modal-content {
    border-radius: 15px;
}


.qr-container {
    display: flex;
    justify-content: center;
    padding: 30px 0px;
    height: 300px;
}


.mantine-Modal-header {
    display: none;
}

canvas {
    object-fit: scale-down;
}


.campaign-container {
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    gap: 35px;
}

.camp-card-text {
    position: absolute;
    z-index: 15;
    display: flex;
    height: 27vh;
    justify-content: space-between;
    align-items: flex-end;

    color: white;
    width: 70%;
    padding: 25px;
}



.camp-card-text>h3 {
    font-size: 20 px;
    font-weight: 700;
    line-height: 27px;
}

.camp-card-text>p {
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
}

.camp-card {
    width: 100%;
    height: fit-content;
}

.camp-card>img,
.banner-image-preview {
    width: 100%;
    border-radius: 17px;
    object-fit: cover;
    filter: brightness(0.4);
    height: 27vh;
}

.edit-button {
    width: 100%;
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.edit-button>i {
    color: var(--main-color);
}

.edit-button>span {
    color: var(--main-color);
    font-size: var(--px16);
    font-weight: 600;
}


.mantine-Menu-itemIcon>i {
    color: var(--main-color);
    font-size: 20px;

}




.opener-btn {
    width: fit-content;
    background-color: var(--main-color);
    color: white;
    display: flex;
    ;
    font-size: 13px;
    align-items: center;
    gap: 5px;
    padding: 5px 12px;
    border-radius: 50px;
}


.opener-btn>i {
    font-size: 17px;

}




.campaign-text {
    font-weight: 400;
    font-size: var(--px15);
    line-height: 27px;

}

.m-b9131032 {
    color: var(--main-color) !important;
}

.mantine-Spoiler-control {
    color: var(--main-color);
}

.campaign-text-container {
    margin-top: 20px;
}

.campaign-hero>img {
    width: 100%;
    object-fit: cover;
    height: 340px;
}

.campaign-hero {
    left: 0px;
    width: 100%;
    height: fit-content;

    transition: 0.3s ease;

}

.prize-strip {
    display: flex;
}


.atcb-button-wrapper {
    padding: 0px !important;
}

.atcb-button-wrapper,
.atcb-list,
.atcb-modal-box {
    padding: 0px !important;
}

#div {
    background-color: rgb(23, 23, 23)
}

.atcb-initialized>div {
    padding: 0px;
}

.mantine-DatePickerInput-day,
.mantine-89cc3j {
    background-color: blue;
}

.admin-login {

    right: 0px;
    top: 0px;
    font-weight: 600;
    padding: 12px;
    background-color: transparent;
    border: none;
    color: black;
    font-size: var(--px15);
    z-index: 10;
    width: 100%;
    text-align: right;
    margin-bottom: -29px;
    opacity: 0.8;

}

.signout-btn {
    background-color: transparent;
    border: none;
    color: var(--main-color);
    font-weight: 600;
    margin: 20px;
    margin-left: 0px;
}

.mantine-pq79tj[data-selected] {
    background-color: var(--main-color) !important;
}

.atcb-text {
    font-size: 14px !important;
}

.mantine-UnstyledButton-root {

    background-color: transparent;
    border: none;
    font-size: var(--px15);
    font-weight: 600;
    padding: 20px 0px;
    color: rgb(125, 125, 125);
    border-bottom: 2.5px solid transparent;

}

.mantine-Tabs-tabsList {
    display: flex;
    gap: 25px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.081);
    ;

}

.mantine-UnstyledButton-root[data-active="true"] {
    border-bottom: solid 2.5px var(--main-color);
    color: var(--main-color);
    font-weight: 600;
}









.btn-default {
    border: none;
    background-color: transparent;
    width: fit-content;
}

.btn-default>.share-btn {
    width: fit-content;
}


.donate-btn-group {
    display: flex;
    gap: 15px;
    margin-top: 20px;

}



.donate-group-text {
    font-size: var(--px15);
    font-weight: 500;

    text-align: center;
    margin-top: 10px;
}

.donate-group-text>span {
    font-weight: 600;

}

.donate-btn-group>button {
    width: 100%;
    border: none;
    background-color: var(--main-color);
    color: white;
    font-weight: 600;
    font-size: var(--px16);
    padding: 15px;
    border-radius: 113px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.donate-btn-group>button>i {
    font-size: 20px;
}

.m-2242eb65 {

    background-color: var(--main-color) !important;
}

.donate-btn-group>button:last-of-type {
    background-color: var(--grayBackground);
    color: black;
}

.donate-floater {
    position: sticky;
    bottom: 0px;
    width: 100%;
    background-color: white;


}






.logout-nav {
    padding: 17px 22px;
    background-color: white;
    position: sticky;
    z-index: 100;
    top: 0px;

}



.logout-nav>button {
    color: black;
    font-weight: 600;
    border: none;
    background-color: transparent;
    font-size: var(--px17);
}

.ambient-strip {
    display: flex;
    justify-content: center;
    padding: 2px 19px;
    padding-bottom: 13px;
}

.ambient-strip>p {
    font-weight: 500;
}

.ambient-strip>p>span {
    font-weight: 600;
    color: var(--main-color);
}

.ambient-strip>button {
    color: black;
    font-weight: 600;
    border: none;
    background-color: transparent;
    font-size: var(--px17);
}









.strip-btn {
    background-color: transparent;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border: none;
    width: 80px;
}

.prize-text-strip {
    display: flex;
    justify-content: space-between;
}

.prize-text>p {
    color: var(--text-gray);
    font-size: var(--px14);
    font-weight: 400;
}

.prize-text>p>span {
    font-weight: 400;
    font-size: var(--px11);
    color: black;
}

.prize-text>h3 {
    font-size: var(--px16);
    font-weight: 500;
}

.prizes-item {
    display: flex;
    flex-direction: column;
    gap: 20px;
    ;
}

.prizes-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.prizes-item {}

.prizes-container>.prizes-item:first-of-type {
    margin-top: 20px;
}

.inline-options {
    display: flex;
    ;
    z-index: 15;
    margin-top: 10px;
    justify-content: flex-end;
    margin-bottom: -70px;


}

.inline-options>i {
    color: white;
    padding: 15px;
}

.mantine-Menu-itemRightSection>i {
    color: #c7c7c7;
}

.mantine-0 {
    stroke: var(--main-color) !important;
}







.status-container {
    height: 100vh;

}



.status-text {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    flex-direction: column;
    gap: 10px;
}



.status-text>p {
    font-size: 45px;
    font-weight: 700;
    text-align: center;
    line-height: 55px;
}

.status-text>span {
    font-size: 16px;
    text-align: center;
}

.status-text>span>a {
    color: var(--main-color);
    font-weight: 600;
}

.status-text>p>span {
    color: var(--main-color);
}

.status-actions {
    padding: 15px;
    padding-bottom: calc(1rem + env(safe-area-inset-bottom));




    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}


.status-actions>p {
    text-align: center;
    font-size: var(--px15);
    text-align: center;
    margin-top: 10px;
}

.status-actions>h2 {
    font-size: var(--px17);
    font-weight: 600;
    width: 100%;
    text-align: center;
    color: var(--text-gray);
}





























.hero-card {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.content-card-btns {
    display: flex;

    gap: 15px;
}

.content-card-title {
    font-size: var(--px18);
    font-weight: 700;
    color: black
}

.content-card-btns>button {
    background-color: transparent;
    border: none;
    padding: 12px;
    outline: solid 1px var(--hoverBorder);
    border-radius: 150px;
    font-size: var(--px15);
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: rgba(0, 0, 0, 0.75);
    width: 100%
}


.exit-fullscreen {
    top: 80px;
    border-radius: 50px;
    border: none;
    width: fit-content;
    font-weight: 700;
    background-color: var(--main-color);
    padding: 10px 20px !important;
    color: white;
}

.content-card-btns>button:first-of-type {
    background-color: var(--main-color);
    outline: none;
    color: white;
}

.content-card-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 15px;
    margin-top: -185px;
    position: relative;
    z-index: 5;
    max-width: 650px;
    margin: auto;
    margin-top: -165px;
}

.content-card-text>p {
    font-size: var(--px15);
    font-weight: 500;
    color: var(--text-gray);
    text-align: center;
}

.content-card {
    border-radius: 15px;
    background-color: white;
    box-shadow: 0px 2px 26px 0px rgba(0, 0, 0, 0.05);
    width: 100%;
    padding: 25px;

}

.img-group {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.content-card-profile {
    width: 94px;
    height: 94px;
    object-fit: cover;
    border-radius: 150px;
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.11);
}

.content-card-group {
    width: 40px;
    height: 40px;
    border-radius: 31px;
    ;
    background: white;
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.11);
    object-fit: scale-down;
    margin-top: -35px;
    margin-left: 55px;
}

.hero-overlay {
    position: absolute;
    width: 100%;
    height: 150px;
    background-color: var(--main-color);
    opacity: 0.5;
    height: 340px;
}

.content-card-text>h2 {
    font-size: var(--px18);
    font-weight: 600;
    text-align: center;
    color: var(--main-color);
}

.content-card-text>h1 {
    font-size: 22px;
    text-align: center;
    line-height: 31px;
}

.read-more-button {
    background-color: transparent;
    border: none;
    color: var(--main-color);
    font-weight: 600;
    font-size: var(--px16);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.modal-pill {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 15px;
    left: 0px;
}

.modal-pill-inner {
    width: 45px;
    height: 5px;
    background-color: #d9d9d9;
    border-radius: 150px;
}

.modal-title {
    font-size: 31px;
    font-weight: 700;
}

.nav-close {
    position: fixed;
    right: 18px;
    top: 35px;
    background-color: var(--main-color);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 160;
    border: none;
}

.nav-close>i {
    color: white;
}

.addition-button {
    background-color: transparent;
    border: none;
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    font-weight: 600;
}

.addition-button>i {
    color: var(--main-color);
}


.ReactSwipeableBottomSheet--open {
    background-color: white !important;
    position: relative;
    z-index: 150;
}

.video-container {
    height: fit-content;
    margin-top: 20px;
}

.video-container>iframe {
    aspect-ratio: 16/9;
    border-radius: 0px 0px 12px 12px;
}

.content-card-immersive {
    padding: 0px;
}

.content-card-immersive>h3 {
    margin-left: 25px;
    margin-top: 25px;
}

.content-text-container {
    display: flex;
    margin-top: 13px;
    gap: 15px;
}

.content-text-container>img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 60px;
}

.m-b9131032 {
    color: var(--main-color) !important;
    font-weight: 500 !important;
}

.search-modal-results {
    display: flex;
    flex-direction: column;
    gap: 25px;
    min-height: 100vh;
}

.search-input {
    width: 100%;
    padding: 15px;
    background-color: var(--grayBackground);
    border: none;
    border-radius: 50px;
    color: black;
}

.search-ribbon {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: solid 1px var(--hoverBorder);
}

.search-ribbon>button {
    background-color: var(--main-color);
    border: none;
    color: white;
    font-weight: 600;
    font-size: var(--px16);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 15px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 55px;
    height: 55px;
}

.search-input::placeholder {
    color: var(--text-gray);
    font-size: var(--px17);
}

.search-ribbon {
    display: flex;
    gap: 10px;
}

.search-modal-results>a {
    margin: 0px;
    padding: 0px;
}

.mountain-text>i {
    font-size: 105px;
    color: var(--main-color);
}

.mountain-text>span {
    font-size: 16px;
    font-weight: 700;
    color: var(--main-color);
    text-transform: uppercase;
    margin-bottom: -5px;
    margin-top: 25px;
}

.mountain-scroll {
    height: -webkit-fill-available;
    overflow-y: scroll;
}

.mountain {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 54px;
    padding-top: 25px;
}

.mountain-text {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 0px 20px;

}

.mountain-text>h2 {
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    margin-top: 7px;
    line-height: 33px;
    width: 290px;

}

.mountain-extra {
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: var(--text-gray);
    margin-top: 5px;
}

.mountain-text>p {
    font-size: var(--px15);
    font-weight: 400;
    margin-top: 0px;
    color: var(--text-gray);
    text-align: center;
}

.floating-edit {

    width: 100%;
    display: flex;
    justify-content: flex-end;
    border: none;
    background-color: transparent;
    margin-bottom: 12px;
    margin-top: -7px;
}

.floating-edit>i {
    color: var(--main-color);
    font-size: 20px;
    background-color: var(--grayBackground);
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    margin-bottom: -40px;
}

.mod-notice {
    background-color: rgb(0 0 0 / 7%);
    display: flex;
    gap: 10px;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
}

.mod-notice>i {
    color: rgb(255, 58, 58)
}

.mod-notice-text>h3 {
    font-size: 17px;
    font-weight: 700;
    color: rgb(255, 58, 58)
}

.mod-notice-text>p {
    font-size: 15px;
    font-weight: 500;
    color: var(--text-gray);
}

.disabled-button {
    background-color: var(--grayBackground);
    color: black;

    pointer-events: none;
    cursor: not-allowed;
}

.disabled-form>* {
    opacity: 0.3;
    pointer-events: none;
}

.mobile-disable {
    display: block;
}



.footer-app-legal {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: var(--px14);
    margin-bottom: 15px;
}

.legal-link {
    text-decoration: underline;
    cursor: pointer;
}

.terms-conditions {
    color: white;
    width: 100%;
    text-align: center;
    font-size: var(--px14);
}

.terms-conditions>a {
    color: white;
    text-decoration: underline;
    font-weight: 600;
}

.gdpr {
    padding: 35px;
    max-width: 570px;
    margin: auto;
}

.gdpr-btn-group {
    display: flex;
    gap: 15px;
    margin-top: 18px;
    flex-direction: column;
}

.do-not-sell-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
}

.btn-group {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.btn {
    color: white;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border: var(--frostedBorder);
    background-color: var(--frostedBackground);
    font-size: var(--px15);
    font-weight: 600;
    padding: 13px 25px;
    min-width: 170px;
    border-radius: 50px;

}

.nav-btn {
    border: solid 1px var(--hoverBorder);
    color: black;
}

.btn-group>.btn:first-of-type {
    background-color: white;
    color: black;
}




.content-team-container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.content-team-container>p {
    font-size: 13px;
    font-weight: 500;
    opacity: 0.7;
    margin-top: 10px;
}

.full-team-text>h1 {
    text-transform: uppercase;
    font-style: italic;
}


.content-team {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-radius: 57px;
    height: 70px;
    padding: 0px 20px;
    align-items: center;

}

.content-team-text>i {
    font-size: 24px;
    color: white;
}

.content-team-text>h4 {
    font-size: 20px;
    font-weight: 800;
    color: white;
    text-transform: uppercase;
    font-style: italic;
}


.content-team-text {
    display: flex;
    align-items: center;
    gap: 10px;
}


.expand-team {
    background-color: rgba(0, 0, 0, 0.12);
    border: none;
    color: white;
    font-weight: 600;
    display: flex;
    gap: 7px;
    align-items: center;
    padding: 7px 0px;
    height: fit-content;
    font-size: 14px;
    ;
    border-radius: 30px
}

.full-team-text {}

@media screen and (min-width: 1130px) {
    .error-guider {
        max-width: 600px;
        margin: auto;
        padding-top: 250px;
    }
}

@media screen and (max-width: 1200px) {

    .footer-app-legal {
        flex-direction: column;
    }



}

@media screen and (max-width: 1130px) {

    .launch-container {
        margin-top: -145px;
    }
}


@media screen and (max-width: 1130px) {

    .launch-container {
        margin-top: -165px
    }

    .onboard-content {
        width: 92%;
        margin-top: 150px;
        margin: auto;
        padding: 15px;
        height: fit-content;
        border-radius: 16px;
        background-color: white;
        padding: 20px;
    }

    .launch-viewer {
        width: 90%;

    }

    .launch-text>h1 {
        font-size: 36px;
        font-weight: 700;
        text-align: center;
        color: black;
        line-height: 48px;
    }

    .launch-text {
        height: 320px;
    }

    .launch-text>p {
        font-size: 15px;
        font-weight: 500;
        text-align: center;
        line-height: 25px;
        width: 80%;


    }
}